import React, {Component} from 'react';
import {Col, Container, Row} from "reactstrap";


export default class ArticlesComponent extends Component {
    render() {

        return (

            <Container>

		<div data-aos="fade-up" data-aos-offset="100" style={{
                    fontSize: 25,
                    textAlign: 'left',
                    wordSpacing: 3,
                    paddingTop: 5,
                    paddingBottom: 15,
                    color: 'black',
                    fontWeight:"bold"
                    // marginTop:10
                    // fontFamily:'Itim',
                }}>
                    Volume 02 | Issue 02
                </div>
		<div data-aos="fade-up" data-aos-offset="100" style={{
                    fontSize: 15,
                    textAlign: 'left',
                    wordSpacing: 3,
                    paddingTop: 5,
                    paddingBottom: 0,
                    color: 'black',
                    fontWeight:"bold"
                    // marginTop:10
                    // fontFamily:'Itim',
                }}>
                    <a style={{textDecoration:'none'}} href='assets/articles/2024-2-AJPS.pdf' target='_blank' rel="noreferrer">
                        Full Paper
                    </a>
                </div>

		<hr data-aos="fade-up" data-aos-offset="100"/>
		<div data-aos="fade-up" data-aos-offset="100" style={{
                    fontSize: 15,
                    textAlign: 'left',
                    wordSpacing: 3,
                    paddingTop: 5,
                    paddingBottom: 0,
                    color: 'black',
                    fontWeight:"bold"
                    // marginTop:10
                    // fontFamily:'Itim',
                }}>
                    <a style={{textDecoration:'none'}} href='assets/articles/2024-2-AJPS-01.pdf' target='_blank' rel="noreferrer">
                    The Effect of Long-Distance Leadership and Work from Home on the Employees� Performance of the Public Sector Workers in Sri Lanka
                    </a>
       		</div>
        	<div data-aos="fade-up" data-aos-offset="100" style={{
                    fontSize: 15,
                    textAlign: 'left',
                    wordSpacing: 3,
                    paddingTop: 3,
                    paddingBottom: 15,
                    color: 'black',
                    // fontWeight:"bold"
                    // marginTop:10
                    // fontFamily:'Itim',
                }}>
                    B. Y. G. W. Miththrananda
        	</div>

        	<hr data-aos="fade-up" data-aos-offset="100"/>
		<div data-aos="fade-up" data-aos-offset="100" style={{
                    fontSize: 15,
                    textAlign: 'left',
                    wordSpacing: 3,
                    paddingTop: 5,
                    paddingBottom: 0,
                    color: 'black',
                    fontWeight:"bold"
                    // marginTop:10
                    // fontFamily:'Itim',
                }}>
                    <a style={{textDecoration:'none'}} href='assets/articles/2024-2-AJPS-02.pdf' target='_blank' rel="noreferrer">
                    A Study of Victims� Perceptions on Post War Reconciliation Efforts by the Government of Sri Lanka
                    </a>
                </div>
                <div data-aos="fade-up" data-aos-offset="100" style={{
                    fontSize: 15,
                    textAlign: 'left',
                    wordSpacing: 3,
                    paddingTop: 3,
                    paddingBottom: 15,
                    color: 'black',
                    // fontWeight:"bold"
                    // marginTop:10
                    // fontFamily:'Itim',
                }}>
                    Suranjith Gunasekara
                </div>

        	<hr data-aos="fade-up" data-aos-offset="100"/>
		<div data-aos="fade-up" data-aos-offset="100" style={{
                    fontSize: 15,
                    textAlign: 'left',
                    wordSpacing: 3,
                    paddingTop: 5,
                    paddingBottom: 0,
                    color: 'black',
                    fontWeight:"bold"
                    // marginTop:10
                    // fontFamily:'Itim',
                }}>
                    <a style={{textDecoration:'none'}} href='assets/articles/2024-2-AJPS-03.pdf' target='_blank' rel="noreferrer">
                    The Patterns of Short Video Usage and Its Impact and Consequences for Older Adults in Rural China
                    </a>
                </div>
                <div data-aos="fade-up" data-aos-offset="100" style={{
                    fontSize: 15,
                    textAlign: 'left',
                    wordSpacing: 3,
                    paddingTop: 3,
                    paddingBottom: 15,
                    color: 'black',
                    // fontWeight:"bold"
                    // marginTop:10
                    // fontFamily:'Itim',
                }}>
                    Hu Liang
                </div>

        	<hr data-aos="fade-up" data-aos-offset="100"/>
		<div data-aos="fade-up" data-aos-offset="100" style={{
                    fontSize: 15,
                    textAlign: 'left',
                    wordSpacing: 3,
                    paddingTop: 5,
                    paddingBottom: 0,
                    color: 'black',
                    fontWeight:"bold"
                    // marginTop:10
                    // fontFamily:'Itim',
                }}>
                    <a style={{textDecoration:'none'}} href='assets/articles/2024-2-AJPS-04.pdf' target='_blank' rel="noreferrer">
                    Applying the �One-Stop-Shop� Strategy for a Citizen-Centric Public Service Delivery in Sri Lanka: A Case Study of Magam Ruhunupura 
                    Administrative Building Complex
                    </a>
                </div>
                <div data-aos="fade-up" data-aos-offset="100" style={{
                    fontSize: 15,
                    textAlign: 'left',
                    wordSpacing: 3,
                    paddingTop: 3,
                    paddingBottom: 15,
                    color: 'black',
                    // fontWeight:"bold"
                    // marginTop:10
                    // fontFamily:'Itim',
                }}>
                    Mayuri Kularathne
                </div>

        	<hr data-aos="fade-up" data-aos-offset="100"/>
		<div data-aos="fade-up" data-aos-offset="100" style={{
                    fontSize: 15,
                    textAlign: 'left',
                    wordSpacing: 3,
                    paddingTop: 5,
                    paddingBottom: 0,
                    color: 'black',
                    fontWeight:"bold"
                    // marginTop:10
                    // fontFamily:'Itim',
                }}>
                    <a style={{textDecoration:'none'}} href='assets/articles/2024-2-AJPS-05.pdf' target='_blank' rel="noreferrer">
                    The Modern Role of the Military in Ensuring National Security from Fighting Insurgency to Human Security in Sri Lanka: 
                    A Case Study of the Northern Province
                    </a>
                </div>
                <div data-aos="fade-up" data-aos-offset="100" style={{
                    fontSize: 15,
                    textAlign: 'left',
                    wordSpacing: 3,
                    paddingTop: 3,
                    paddingBottom: 15,
                    color: 'black',
                    // fontWeight:"bold"
                    // marginTop:10
                    // fontFamily:'Itim',
                }}>
                    D. G. R. N. Rukshan
                </div>

























		{/*<hr data-aos="fade-up" data-aos-offset="100"/>
		<div data-aos="fade-up" data-aos-offset="100" style={{
                   fontSize: 15,
                   textAlign: 'left',
                   wordSpacing: 3,
                   paddingTop: 5,
                   paddingBottom: 0,
                   color: 'black',
                  fontWeight:"bold"
                  // marginTop:10
                 // fontFamily:'Itim',
                }}>
                    <a style={{textDecoration:'none'}} href='assets/articles/2024-1-AJPS-01.pdf' target='_blank' rel="noreferrer">
                        Sociocultural Discourses of Suicide in Sri Lanka: An Overview of Literature
                    </a>
                </div>
                <div data-aos="fade-up" data-aos-offset="100" style={{
                    fontSize: 15,
                    textAlign: 'left',
                    wordSpacing: 3,
                    paddingTop: 3,
                    paddingBottom: 15,
                    color: 'black',
                    // fontWeight:"bold"
                    // marginTop:10
                    // fontFamily:'Itim',
                }}>
                    Susantha Rasnayake
                </div>
                <hr data-aos="fade-up" data-aos-offset="100"/>
		<div data-aos="fade-up" data-aos-offset="100" style={{
                    fontSize: 15,
                    textAlign: 'left',
                    wordSpacing: 3,
                    paddingTop: 5,
                    paddingBottom: 0,
                    color: 'black',
                    fontWeight:"bold"
                    // marginTop:10
                    // fontFamily:'Itim',
                }}>
                    <a style={{textDecoration:'none'}} href='assets/articles/2024-1-AJPS-02.pdf' target='_blank' rel="noreferrer">
                        The Role of Microcredit on Women Empowerment in Sri Lanka: Evidence from Samurdhi Program Beneficiaries in Weligama Divisional Secretariat Division                    </a>
                </div>
                <div data-aos="fade-up" data-aos-offset="100" style={{
                    fontSize: 15,
                    textAlign: 'left',
                    wordSpacing: 3,
                    paddingTop: 3,
                    paddingBottom: 15,
                    color: 'black',
                    // fontWeight:"bold"
                    // marginTop:10
                    // fontFamily:'Itim',
                }}>
                    A.A.F. Ilma
                </div>
                <hr data-aos="fade-up" data-aos-offset="100"/>
		<div data-aos="fade-up" data-aos-offset="100" style={{
                    fontSize: 15,
                    textAlign: 'left',
                    wordSpacing: 3,
                    paddingTop: 5,
                    paddingBottom: 0,
                    color: 'black',
                    fontWeight:"bold"
                    // marginTop:10
                    // fontFamily:'Itim',
                }}>
                    <a style={{textDecoration:'none'}} href='assets/articles/2024-1-AJPS-03.pdf' target='_blank' rel="noreferrer">
                        Impact of Financial Literacy on the Performance of Small and Medium-Sized Enterprises: Special Reference to Colombo District
		    </a>
                </div>
                <div data-aos="fade-up" data-aos-offset="100" style={{
                    fontSize: 15,
                    textAlign: 'left',
                    wordSpacing: 3,
                    paddingTop: 3,
                    paddingBottom: 15,
                    color: 'black',
                    // fontWeight:"bold"
                    // marginTop:10
                    // fontFamily:'Itim',
                }}>
                    G.G.K. Janithri
                </div>
                <hr data-aos="fade-up" data-aos-offset="100"/>
		<div data-aos="fade-up" data-aos-offset="100" style={{
                    fontSize: 15,
                    textAlign: 'left',
                    wordSpacing: 3,
                    paddingTop: 5,
                    paddingBottom: 0,
                    color: 'black',
                    fontWeight:"bold"
                    // marginTop:10
                    // fontFamily:'Itim',
                }}>
                    <a style={{textDecoration:'none'}} href='assets/articles/2024-1-AJPS-04.pdf' target='_blank' rel="noreferrer">
                        Differential Impact on the Students� Performance in the Grade Five Scholarship Examination in Sri Lanka: Based on the Nuwara-Eliya District
		    </a>
                </div>
                <div data-aos="fade-up" data-aos-offset="100" style={{
                    fontSize: 15,
                    textAlign: 'left',
                    wordSpacing: 3,
                    paddingTop: 3,
                    paddingBottom: 15,
                    color: 'black',
                    // fontWeight:"bold"
                    // marginTop:10
                    // fontFamily:'Itim',
                }}>
                    R.M.L. Rathnayake
                </div>
                <hr data-aos="fade-up" data-aos-offset="100"/>
		<div data-aos="fade-up" data-aos-offset="100" style={{
                    fontSize: 15,
                    textAlign: 'left',
                    wordSpacing: 3,
                    paddingTop: 5,
                    paddingBottom: 0,
                    color: 'black',
                    fontWeight:"bold"
                    // marginTop:10
                    // fontFamily:'Itim',
                }}>
                    <a style={{textDecoration:'none'}} href='assets/articles/2024-1-AJPS-05.pdf' target='_blank' rel="noreferrer">
                        A Book Review of Destined for War: Can America and China Escape Thucydides's Trap?
		    </a>
                </div>
                <div data-aos="fade-up" data-aos-offset="100" style={{
                    fontSize: 15,
                    textAlign: 'left',
                    wordSpacing: 3,
                    paddingTop: 3,
                    paddingBottom: 15,
                    color: 'black',
                    // fontWeight:"bold"
                    // marginTop:10
                    // fontFamily:'Itim',
                }}>
                    Aruna Jayathilaka
                </div>
                <hr data-aos="fade-up" data-aos-offset="100"/>*/}


                               

                {/*<hr data-aos="fade-up" data-aos-offset="100" />*/}
                {/*<div data-aos="fade-up" data-aos-offset="100" style={{*/}
                {/*    fontSize: 15,*/}
                {/*    textAlign: 'left',*/}
                {/*    wordSpacing: 3,*/}
                {/*    paddingTop: 5,*/}
                {/*    paddingBottom: 0,*/}
                {/*    color: 'black',*/}
                {/*    fontWeight:"bold"*/}
                {/*    // marginTop:10*/}
                {/*    // fontFamily:'Itim',*/}
                {/*}}>*/}
                {/*    <a style={{textDecoration:'none'}} href='assets/articles/2022-AA-JPN-15.pdf' target='_blank'>*/}
                {/*        謝罪場面での「事情説明」における自動詞・他動詞の使用状況 ―スリランカの日本語学習者を対象に―*/}
                {/*    </a>*/}
                {/*</div>*/}
                {/*<div data-aos="fade-up" data-aos-offset="100" style={{*/}
                {/*    fontSize: 15,*/}
                {/*    textAlign: 'left',*/}
                {/*    wordSpacing: 3,*/}
                {/*    paddingTop: 3,*/}
                {/*    paddingBottom: 15,*/}
                {/*    color: 'black',*/}
                {/*    // fontWeight:"bold"*/}
                {/*    // marginTop:10*/}
                {/*    // fontFamily:'Itim',*/}
                {/*}}>*/}
                {/*    D.P.O. Dassanayaka*/}
                {/*</div>*/}

                {/*<hr data-aos="fade-up" data-aos-offset="100" />*/}
                {/*<div data-aos="fade-up" data-aos-offset="100" style={{*/}
                {/*    fontSize: 15,*/}
                {/*    textAlign: 'left',*/}
                {/*    wordSpacing: 3,*/}
                {/*    paddingTop: 5,*/}
                {/*    paddingBottom: 0,*/}
                {/*    color: 'black',*/}
                {/*    fontWeight:"bold"*/}
                {/*    // marginTop:10*/}
                {/*    // fontFamily:'Itim',*/}
                {/*}}>*/}
                {/*    <a style={{textDecoration:'none'}} href='assets/articles/2022-AA-JPN-18.pdf' target='_blank'>*/}
                {/*        シンハラ語と日本語の対照研究 ― 文学翻訳における言語構造分析を通して ―*/}
                {/*    </a>*/}
                {/*</div>*/}
                {/*<div data-aos="fade-up" data-aos-offset="100" style={{*/}
                {/*    fontSize: 15,*/}
                {/*    textAlign: 'left',*/}
                {/*    wordSpacing: 3,*/}
                {/*    paddingTop: 3,*/}
                {/*    paddingBottom: 15,*/}
                {/*    color: 'black',*/}
                {/*    // fontWeight:"bold"*/}
                {/*    // marginTop:10*/}
                {/*    // fontFamily:'Itim',*/}
                {/*}}>*/}
                {/*    Y. Nishina, J.E. Mohotti*/}
                {/*</div>*/}

                {/*<hr data-aos="fade-up" data-aos-offset="100" />*/}
                {/*<div data-aos="fade-up" data-aos-offset="100" style={{*/}
                {/*    fontSize: 15,*/}
                {/*    textAlign: 'left',*/}
                {/*    wordSpacing: 3,*/}
                {/*    paddingTop: 5,*/}
                {/*    paddingBottom: 0,*/}
                {/*    color: 'black',*/}
                {/*    fontWeight:"bold"*/}
                {/*    // marginTop:10*/}
                {/*    // fontFamily:'Itim',*/}
                {/*}}>*/}
                {/*    <a style={{textDecoration:'none'}} href='assets/articles/2022-AA-SNH-4.pdf' target='_blank'>*/}
                {/*        දිඹුලාගල වෙසෙන වැදි ජනයාගේ ජන ඇදහිලි සහ විශ්වාසවල අද්‍යතන ස්වරූපය පිළිබඳ විමර්ශනාත්මක අධ්‍යයනයක් : දිඹුලාගල ප්‍රදේශීය ලේකම් කොට්ඨාසයේ පහළ යක්කුරේ, දළුකාන සහ මිල්ලාන ගම්මාන ඇසුරෙනි.*/}
                {/*    </a>*/}
                {/*</div>*/}
                {/*<div data-aos="fade-up" data-aos-offset="100" style={{*/}
                {/*    fontSize: 15,*/}
                {/*    textAlign: 'left',*/}
                {/*    wordSpacing: 3,*/}
                {/*    paddingTop: 3,*/}
                {/*    paddingBottom: 15,*/}
                {/*    color: 'black',*/}
                {/*    // fontWeight:"bold"*/}
                {/*    // marginTop:10*/}
                {/*    // fontFamily:'Itim',*/}
                {/*}}>*/}
                {/*    ආර්.ඒ.ඩී. ප්‍රියංකා වීරසේකර, එච්.එම්. බුද්ධික හේරත්*/}
                {/*</div>*/}


                {/*<hr data-aos="fade-up" data-aos-offset="100" />*/}
                {/*<div data-aos="fade-up" data-aos-offset="100" style={{*/}
                {/*    fontSize: 15,*/}
                {/*    textAlign: 'left',*/}
                {/*    wordSpacing: 3,*/}
                {/*    paddingTop: 5,*/}
                {/*    paddingBottom: 0,*/}
                {/*    color: 'black',*/}
                {/*    fontWeight:"bold"*/}
                {/*    // marginTop:10*/}
                {/*    // fontFamily:'Itim',*/}
                {/*}}>*/}
                {/*    <a style={{textDecoration:'none'}} href='assets/articles/2022-AA-SNH-9.pdf' target='_blank'>*/}
                {/*        අපේක්ෂාභංගත්වය, ඛේදනීය සමාජ ප්‍රතිවිපාක කෙරෙහි බලපාන ආකාරය ඇනා කැරනිනා නවකතාව ඇසුරින් කෙරෙන අධ්‍යයනයකි*/}
                {/*    </a>*/}
                {/*</div>*/}
                {/*<div data-aos="fade-up" data-aos-offset="100" style={{*/}
                {/*    fontSize: 15,*/}
                {/*    textAlign: 'left',*/}
                {/*    wordSpacing: 3,*/}
                {/*    paddingTop: 3,*/}
                {/*    paddingBottom: 15,*/}
                {/*    color: 'black',*/}
                {/*    // fontWeight:"bold"*/}
                {/*    // marginTop:10*/}
                {/*    // fontFamily:'Itim',*/}
                {/*}}>*/}
                {/*    බී.වී.අයි.එස්. ධර්මසේන, චම්පා එස්.ද සිල්වා*/}
                {/*</div>*/}


                {/*<hr data-aos="fade-up" data-aos-offset="100" />*/}
                {/*<div data-aos="fade-up" data-aos-offset="100" style={{*/}
                {/*    fontSize: 15,*/}
                {/*    textAlign: 'left',*/}
                {/*    wordSpacing: 3,*/}
                {/*    paddingTop: 5,*/}
                {/*    paddingBottom: 0,*/}
                {/*    color: 'black',*/}
                {/*    fontWeight:"bold"*/}
                {/*    // marginTop:10*/}
                {/*    // fontFamily:'Itim',*/}
                {/*}}>*/}
                {/*    <a style={{textDecoration:'none'}} href='assets/articles/2022-AA-TML-11.pdf' target='_blank'>*/}
                {/*        உரைநரை வளர்ச்சியில் ஆர்zல்ட் சதாசிவம் பிள்ரள*/}
                {/*    </a>*/}
                {/*</div>*/}
                {/*<div data-aos="fade-up" data-aos-offset="100" style={{*/}
                {/*    fontSize: 15,*/}
                {/*    textAlign: 'left',*/}
                {/*    wordSpacing: 3,*/}
                {/*    paddingTop: 3,*/}
                {/*    paddingBottom: 15,*/}
                {/*    color: 'black',*/}
                {/*    // fontWeight:"bold"*/}
                {/*    // marginTop:10*/}
                {/*    // fontFamily:'Itim',*/}
                {/*}}>*/}
                {/*    S.R. Devakumari*/}
                {/*</div>*/}

                {/*<hr data-aos="fade-up" data-aos-offset="100" />*/}
                {/*<div data-aos="fade-up" data-aos-offset="100" style={{*/}
                {/*    fontSize: 15,*/}
                {/*    textAlign: 'left',*/}
                {/*    wordSpacing: 3,*/}
                {/*    paddingTop: 5,*/}
                {/*    paddingBottom: 0,*/}
                {/*    color: 'black',*/}
                {/*    fontWeight:"bold"*/}
                {/*    // marginTop:10*/}
                {/*    // fontFamily:'Itim',*/}
                {/*}}>*/}
                {/*    <a style={{textDecoration:'none'}} href='assets/articles/2022-AA-TRL-8.pdf' target='_blank'>*/}
                {/*        Study on the Effectiveness of Using Google Translate in Legal Translation: With Special Reference to Selected Legal Documents of the Registrar General’s Department*/}
                {/*    </a>*/}
                {/*</div>*/}
                {/*<div data-aos="fade-up" data-aos-offset="100" style={{*/}
                {/*    fontSize: 15,*/}
                {/*    textAlign: 'left',*/}
                {/*    wordSpacing: 3,*/}
                {/*    paddingTop: 3,*/}
                {/*    paddingBottom: 15,*/}
                {/*    color: 'black',*/}
                {/*    // fontWeight:"bold"*/}
                {/*    // marginTop:10*/}
                {/*    // fontFamily:'Itim',*/}
                {/*}}>*/}
                {/*    P.D.N.M. Ubhayawardhana, J.A.M. Hansani*/}
                {/*</div>*/}


		</Container>


        );
    }
}
